import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, theme } from './styles'
import { Card, Content, Grid, Title, Numbers, NewButton, Loading, SideBar, SideAds } from './components'
import { configureStore } from './core'
const store = configureStore()

// store.dispatch(fetchGrid)

ReactDOM.render(

    <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Provider store={store}>
            <SideAds />
            <Content>
                <Title style={{color: theme.colors.black}}>Sudoku</Title>
                <Card>
                    <Loading>
                    <>
                    <NewButton />
                    <Grid />
                    <Numbers />
                    </>
                    </Loading>
                </Card>
            </Content>
            <SideBar />
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
)
