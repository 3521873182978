// eslint-disable-next-line
export default {
    colors: {
        black: '#202025',
        blue: '#a0e9fd',
        lightBlue: '#caf3fe',
        white: '#F1F3F4',
        green: '#3DDD85',
        deepblue: '#4285F4',
        brown: '#FFC903',
        orange: '#FA8801'
    },
    transition: '0.3s',
}
// export default {
//     colors: {
//         background: 'radial-gradient(#0f0c29, #302b63)',
//         black: '#302b63',
//         blue: '#a0e9fd',
//         lightBlue: '#caf3fe',
//         white: 'white',
//         green: '#5BFF5B',
//         deepblue: '#5B5BFF',
//         brown: '#FFA44A'
//     },
//     transition: '0.3s',
// }
