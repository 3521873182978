import React, {useCallback} from 'react'
import {Button} from '../../styles/button'
import {useDispatch, useSelector} from 'react-redux'
import { fillBlock } from '../../../reducers/actions'


const NumberButton = ({value}) => {

    const state = useSelector(({challengeGrid, selectedBlock}) => ({
        selectedBlock,
        selectedValue: challengeGrid && selectedBlock ? challengeGrid[selectedBlock[0]][selectedBlock[1]] : 0
    }))

    const dispatch = useDispatch()

    const fill = useCallback(()=>{
        if (state.selectedBlock && state.selectedValue === 0) {
            dispatch(fillBlock(value, state.selectedBlock))
        }
    }, [dispatch, state.selectedBlock, state.selectedValue, value])

    return (
        <Button onClick={fill}>{value}</Button>
    )
}

export default NumberButton