import React, {useState, useEffect} from 'react'
import { useAudio } from "react-use";
const success = require('./success.wav')
const end1 = require('./end1.wav')
const great = require('./great.wav')
const PlayMusic = ({stage}) => {
  const sound = stage ? stage === '1' ? great : stage === '2' ? success : stage === '3'? end1 : null : null
  const [audio, state, controls, ref] = useAudio({
    src: sound,
    autoPlay: true
  })
  return (
      <>
          {audio}
      </>
  )
}


// const PlayMusic = ({stage}) => {
//   const sound = stage ? stage === '1' ? great : stage === '2' ? success : stage === '3'? end : null : null
//   console.log(sound)
//   const [audiok, setAudio] = useState(new Audio(sound));
//   useEffect(() => {
//       audiok.play()
//       return () => {
//         setAudio('')
//       }
//     }, []

//   );
//   return <></>
// }

export default PlayMusic
