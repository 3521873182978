import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${({ theme, puzzle, active, isHint }) => css`
        align-items: center;
        background-color: ${active ? theme.colors.blue : isHint.length? isHint[0][0] === 1 ? theme.colors.brown: isHint[0][0] === 2? theme.colors.green: theme.colors.deepblue: theme.colors.white};
        border: solid 1px ${theme.colors.black};
        cursor: pointer;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        font-weight: ${puzzle ? 'normal': 'bold'};
        font-size: 20px;
        height: auto;
        justify-content: center;
        transition: ${theme.transition};
        user-select: none;

        &:before {
            padding-top: 100%;
            content: '';
            float: left;
        }

        &:hover {
            background-color: ${theme.colors.lightBlue};
        }
    `}
`
