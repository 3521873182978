import * as types from './types'
import Axios from 'axios'
import { initialGrid } from '../utils/initialGrid'
import { compareArray } from '../utils/compareArrays'
import { copyGrid } from '../utils/copyGrid'

const initialState = {}

function reducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_GRID: {
            return {
                workingGrid: copyGrid(action.payload.challengeGrid),
                ...action.payload,
                loading: false,
                isCompleted: false,
                isCorrect: false,
                isThirfty: -1,
            }
        }

        case types.FILL_BLOCK:
            if (state.workingGrid && state.solvedGrid) {
                //can handle check here

                state.workingGrid[action.coordinate[0]][action.coordinate[1]] = action.value

                const isCorrect = state.workingGrid[action.coordinate[0]][action.coordinate[1]] ===
                state.solvedGrid[action.coordinate[0]][action.coordinate[1]]

                const getThirfty = (sudokuGrid) => sudokuGrid.map(a => a.filter( i => i === 0).length).reduce((a, b) => a+b, 0)

                const isThirfty = (getThirfty([...state.challengeGrid]) - getThirfty([...state.workingGrid])) % 3

                if (compareArray(state.workingGrid, state.solvedGrid)) {
                    return {
                        ...state,
                        workingGrid: [...state.workingGrid],
                        isCompleted: true,
                        isCorrect,
                        isThirfty

                    }
                }
                return {
                    ...state,
                    isCompleted: false,
                    isCorrect,
                    isThirfty,
                    workingGrid: [...state.workingGrid],

                }
            }
            return state

        case types.SELECT_BLOCK:
            return {
                ...state,
                selectedBlock: action.coordinate,
                isCorrect: false,
            }

        case types.SET_LEVEL:
            return {
                ...state,
                level: action.level,
                loading: action.loading,
            }


        default:
            return { ...state, isCorrect: false }
    }
}

export default reducer

export async function fetchGrid(dispatch, getState) {
    const response = await Axios.get(`https://us-central1-sudoku123-59b78.cloudfunctions.net/sudoku/${getState().level}`)
    dispatch({ type: types.CREATE_GRID, payload: response.data })
}

// export async function fetchGrid(dispatch, getState) {
//     setTimeout(async () => {
//         const response = await Axios.get(`http://127.0.0.1:4000/data`)
//         dispatch({ type: types.CREATE_GRID, payload: response.data })
//     }, 5000)
// }
