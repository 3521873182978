import { Action } from 'redux'
import * as types from './types'
import axios from 'axios'

export const createGrid = (grid = []) => ({
    type: types.CREATE_GRID,
    grid,
})


export const fillBlock = (value, coordinate) => ({
    type: types.FILL_BLOCK,
    coordinate,
    value
})

export const selectBlock = (coordinate) => ({
    type: types.SELECT_BLOCK,
    coordinate: coordinate
})

export const setLevel = (level, loading) => ({
    loading: loading,
    type: types.SET_LEVEL,
    level: level
})


// export const getGrid = store => next => async action => {
//     if (action.types === types.CREATE_GRID) {
//     try {
//         const grid = await axios('https://localhost:4000/sudoku')
//         if (grid) {
//             store.dispatch(createGrid(grid))
//         } else {
//             store.dispatch(createGrid())
//         }
//     } catch (err) {
//         throw new Error(err)
//     }}
//     return next(action)
// }

export const getGridMiddleware = store => next => action => {
    if (typeof action === 'function') {
        return action(store.dispatch, store.getState)
    }
    return next(action)
}
