import styled, { css } from 'styled-components'

export const Card = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        border-radius: 15px;
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: fit-content;
        padding: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
    `}
`
