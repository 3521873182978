import React, {useCallback, useState} from 'react'

import {Select} from '../styles/newButton'
import {selectOption} from '../styles/selectOption'
import {useDispatch, useSelector} from 'react-redux'
import { fetchGrid } from '../../reducers/reducer'
import { setLevel, createGrid } from '../../reducers/actions'
import Modal from '../modal'

const NewButton = () => {

    const dispatch = useDispatch()

    // const setGridLevel = useCallback(() => {

    // }, [dispatch])


    const createNewGrid = useCallback((e) => {
        // dispatch(createGrid())
        if (window.confirm('Are you sure you want to leave game?')) {
            dispatch(setLevel(e.target.value, true))
            dispatch(fetchGrid)
        }
    }, [dispatch])

    return (
        <>
            <Select value="0" onChange={createNewGrid}>
                <option value="0" disabled >New Game</option>
                <option value="1">Easy</option>
                <option value="2" >Medium</option>
                <option value="3">Hard</option>
            </Select>
        </>
    )


}

export default NewButton
