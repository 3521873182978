import React from 'react'
import { Container } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { selectBlock } from '../../../reducers/actions'


const Block = ({ rowIndex, colIndex, isHint }) => {
    const dispatch = useDispatch()
    const handleClick = () => {
        if (!state.selectedBlock && !state.isComplete)
            dispatch(selectBlock([rowIndex, colIndex]))
    }
    const state = useSelector(
        ({ challengeGrid, workingGrid, selectedBlock, isComplete }) => ({
            selectedBlock: selectedBlock
                ? selectedBlock[0] === rowIndex && selectedBlock[1] === colIndex
                : false,
            isComplete,
            issPuzzle:
                challengeGrid && challengeGrid[rowIndex][colIndex] !== 0
                    ? false
                    : true,
            value: workingGrid ? workingGrid[rowIndex][colIndex] : 0,
        })
    )

    return (
            <Container
                active={state.selectedBlock}
                onClick={handleClick}
                puzzle={state.issPuzzle}
                isHint={isHint}
            >
                {state.value === 0 ? '' : state.value}{' '}
            </Container>
    )
}

export default Block
