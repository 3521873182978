import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
    ${({ theme }) => css`
        html {
            height: 100%;

            body {
                display: flex;
                flex-direction: column;
                height: 100%;
                margin: 0;
                background-image:repeating-linear-gradient(to bottom,transparent,transparent 5px,#fbfbfb 5px,#ffffff 9px), repeating-linear-gradient(to right, transparent,transparent 5px,#fbfbfb 5px,#ffffff 9px);
};

                #root {
                    color: ${theme.colors.black};
                    display: flex;
                    font-family: sans-serif;
                    justify-content: center;
                    padding: 15px;

                @media (max-width: 732px) {
                        display: flex;
                        flex-direction: column;
                    }

                }
            }
        }
    `}
`
