import React from 'react'
import {Container} from './styles'
import Button from './button'

const Numbers = () => (
    <Container>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
            <Button key={value} value={value}/>
        ))}
    </Container>
)

export default Numbers
