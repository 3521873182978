import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import ReactCanvasConfetti from 'react-canvas-confetti'

export const FireworkOne = () => {
    const { width, height } = useWindowSize()

    return (
        <Confetti
            width={width}
            height={height}
            numberOfPieces={200}
            recycle={false}
        />
    )
}

function randomInRange(min, max) {
    return Math.random() * (max - min) + min
}

const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
}

export class Firework extends React.Component {
    constructor(props) {
        super(props)
        this.isAnimationEnabled = false
        this.animationInstance = null
        this.intervalId = null
    }

    getAnimationSettings(originXA, originXB) {
        return {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            zIndex: 0,
            particleCount: 150,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2,
            },
        }
    }

    nextTickAnimation = () => {
        this.animationInstance &&
            this.animationInstance(this.getAnimationSettings(0.1, 0.3))
        this.animationInstance &&
            this.animationInstance(this.getAnimationSettings(0.7, 0.9))
    }

    startAnimation() {
        if (!this.isAnimationEnabled) {
            this.isAnimationEnabled = true
            this.intervalId = setInterval(this.nextTickAnimation, 400)
        }
    }

    pauseAnimation() {
        this.isAnimationEnabled = false
        return this.intervalId && clearInterval(this.intervalId)
    }

    stopAnimation() {
        this.isAnimationEnabled = false
        this.animationInstance && this.animationInstance.reset()
        this.intervalId && clearInterval(this.intervalId)
    }

    handlerClickStart = () => {
        this.startAnimation()
    }

    handlerClickPause = () => {
        this.pauseAnimation()
    }

    handlerClickStop = () => {
        this.stopAnimation()
    }

    componentWillUnmount() {
        this.isAnimationEnabled = false
        this.intervalId && clearInterval(this.intervalId)
    }

    getInstance = (instance) => {
        this.animationInstance = instance

        this.handlerClickStart()
        setInterval(() => this.handlerClickPause(), 10000)
    }

    render() {
        return (
            <>
                <ReactCanvasConfetti
                    refConfetti={this.getInstance}
                    style={canvasStyles}
                />
            </>
        )
    }
}



export class FireworkTwo extends React.Component {
  constructor(props) {
    super(props);
    this.animationInstance = null;
  }

  makeShot = (particleRatio, opts) => {
    this.animationInstance && this.animationInstance({
      ...opts,
      origin: { y: 0.7 },
      particleCount: Math.floor(200 * particleRatio),
    });
  }

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    this.makeShot(0.2, {
      spread: 60,
    });

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  handlerFire = () => {
    this.fire();
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
    this.handlerFire()
  };

  render() {
    return (
      <>
        <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles}/>
      </>
    );
  }
}