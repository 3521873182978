import React, {useCallback, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {fetchGrid} from '../../reducers/reducer'
import {setLevel, createGrid} from '../../reducers/actions'
import {ReactComponent as LoadingSVG} from './loading.svg'


const Loading = (props) => {

    const state = useSelector(({loading}) => ({loading}))
    const dispatch = useDispatch()
    const create = useCallback(() => {
        // dispatch(setLevel(1, false))
        // dispatch(createGrid())
        // dispatch(fetchGrid)
    }, [dispatch])



    useEffect(() => {
        create()
    }, [create])

    return (
        <>
        {state.loading? <LoadingSVG/> : {...props.children}}
        </>

    )
}

export default Loading