import React from 'react'
import {Container, Block} from './styles'
import {Button} from '../styles/button'
import { useSelector } from 'react-redux'

const SideBar = () => {
    const state = useSelector(({loading}) => ({loading}))
    return (
        <>
        {state.loading ?  null : <Container>
            <div>Instructions</div>

            <table style={{fontSize:"12px"}}>
                <tr>
                    <td>
                    <Block bgColor="1" />
                    </td>
                    <td>Easy to solve (addictive) cell</td>
                </tr>
                <tr>
                    <td>
                    <Block bgColor="2" />
                    </td>
                    <td>Very easy to solve (Very Addictive) cell</td>
                </tr>
                <tr>
                    <td>
                    <Block bgColor="3" />
                    </td>
                    <td>Easiest to solve (most addictive) cell</td>
                </tr>
            </table>

        </Container>}
    </>
    )
}

export default SideBar