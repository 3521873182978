import React, { useState } from 'react'
import { Card, Content } from './styles'
import { ReactComponent as Congrats } from './ribbon.svg'
import { Button } from '../styles/button'

const Modal = () => {
    const [isModalOpen, handleModal] = useState(true)

    return (
        <div>
            <ModalChild isOpen={isModalOpen} onClose={() => handleModal(false)}>
                <h3>Congratulations!</h3>
                <Congrats width="120px" height="120px" />
                <p>
                    You're awesome!
                    <br />
                    <p style={{ paddingLeft: '80px' }}>
                        <Button onClick={(e) => handleModal(false)} >&nbsp;Play Again?&nbsp;</Button>
                    </p>
                </p>
            </ModalChild>
        </div>
    )
}

const ModalChild = (props) => {
    const close = (e) => {
        e.preventDefault()

        if (props.onClose) {
            props.onClose()
        }
    }
    return props.isOpen ? (
        <div>
            <Card>{props.children}</Card>
            <Content onClick={(e) => close(e)} />
        </div>
    ) : null
}

export default Modal
