import styled, { css } from 'styled-components'

export const Block = styled.div`
${({ theme, bgColor }) => css`
    align-items: center;
    background-color: ${bgColor === '1' ? theme.colors.brown: bgColor === '2' ? theme.colors.green : theme.colors.deepblue};
    border-radius: 4px;
    color: ${theme.colors.black};
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    height: 24px;
    width: 24px;
    justify-content: center;
    margin: 4px 2px;
    opacity: 0.9;
    padding: 0;
    transition: ${theme.transition};

    &:focus {
        border-color: ${theme.colors.blue} ;
        outline: none;
    }

    &:hover {
        opacity: 0.6;
    }

`}
`

export const Container = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.colors.white};
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 15px;
        display: flex;
        flex: 0.1;
        flex-direction: column;
        max-height: fit-content;
        height: 20%;
        padding: 25px;
        user-select: none;
        margin: 8%;
        border-radius: 15px;
        font-weight: bold;
        font-size: 28px;
    `}
`

