import React, { Children, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Block from './block'
import { Container, Row } from './styles'
import useMouseTrap from 'react-hook-mousetrap'
import { AnyAction, Dispatch } from 'redux'
import {
    setLevel,
    selectBlock,
    fillBlock,
    playSound,
} from '../../reducers/actions'
import { fetchGrid } from '../../reducers/reducer'
import { Firework, FireworkOne, FireworkTwo } from '../firework'
import PlayMusic from '../successMusic'
import Modal from '../modal'

const Grid = () => {
    const [countDone, handleCount] = useState(0)
    const [latestIsCorrect, handleCorrect] = useState(false)
    const state = useSelector(
        ({
            selectedBlock,
            challengeGrid,
            isCompleted,
            hintsArray,
            workingGrid,
            solvedGrid,
            isCorrect,
            isThirfty,
        }) => ({
            selectedBlock,
            workingGrid,
            solvedGrid,
            hintsArray: hintsArray || [],
            isCompleted,
            isCorrect,
            isThirfty,
            selectedValue:
                challengeGrid && selectedBlock
                    ? challengeGrid[selectedBlock[0]][selectedBlock[1]]
                    : 0,
        })
    )

    const dispatch = useDispatch()

    const fill = useCallback(
        (n) => {
            if (state.selectedBlock && state.selectedValue === 0) {
                dispatch(fillBlock(n, state.selectedBlock))
            }
        },
        [dispatch, state.selectedBlock, state.selectedValue]
    )
    const moveDown = () => {
        if (state.selectedBlock && state.selectedBlock[0] < 8) {
            dispatch(
                selectBlock([
                    state.selectedBlock[0] + 1,
                    state.selectedBlock[1],
                ])
            )
        }
    }
    const moveLeft = () => {
        if (state.selectedBlock && state.selectedBlock[1] > 0) {
            dispatch(
                selectBlock([
                    state.selectedBlock[0],
                    state.selectedBlock[1] - 1,
                ])
            )
        }
    }
    const moveRight = () => {
        if (state.selectedBlock && state.selectedBlock[1] < 8) {
            dispatch(
                selectBlock([
                    state.selectedBlock[0],
                    state.selectedBlock[1] + 1,
                ])
            )
        }
    }
    const moveUp = () => {
        if (state.selectedBlock && state.selectedBlock[0] > 0) {
            dispatch(
                selectBlock([
                    state.selectedBlock[0] - 1,
                    state.selectedBlock[1],
                ])
            )
        }
    }

    useMouseTrap('1', () => fill(1))
    useMouseTrap('2', () => fill(2))
    useMouseTrap('3', () => fill(3))
    useMouseTrap('4', () => fill(4))
    useMouseTrap('5', () => fill(5))
    useMouseTrap('6', () => fill(6))
    useMouseTrap('7', () => fill(7))
    useMouseTrap('8', () => fill(8))
    useMouseTrap('9', () => fill(9))

    useMouseTrap('down', moveDown)
    useMouseTrap('down', moveDown)
    useMouseTrap('up', moveUp)
    useMouseTrap('left', moveLeft)
    useMouseTrap('right', moveRight)

    return (
        <>
            {state.isCorrect && !state.isCompleted ? (
                state.isThirfty ? (
                    <>
                        <PlayMusic stage="2" /> <FireworkTwo /> {' '}
                    </>
                ) : (
                    <>
                        <FireworkOne /> <PlayMusic stage="1" />{' '}
                    </>
                )
            ) : null}
            <Container>
                {state.isCompleted ? (
                    <>
                        {' '}
                        <Firework /> <PlayMusic stage="3" />
                        <Modal />{' '}
                    </>
                ) : (
                    ''
                )}
                {Children.toArray(
                    [...Array(9)].map((_, rowIndex) => (
                        <Row>
                            {' '}
                            {Children.toArray(
                                [...Array(9)].map((_, colIndex) => (
                                    <Block
                                        colIndex={colIndex}
                                        rowIndex={rowIndex}
                                        isHint={state.hintsArray.filter(
                                            (cell) =>
                                                cell[1] === rowIndex &&
                                                cell[2] === colIndex
                                        )}
                                    />
                                ))
                            )}{' '}
                        </Row>
                    ))
                )}{' '}
            </Container>
        </>
    )
}

export default Grid
