import React, { useEffect } from 'react'
import {Container} from './styles'

const SideAds = () => {
    useEffect(() => {
        const installGoogleAds = () => {
            const elem = document.createElement('script')
            elem.src =
                '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6350015590431986'
            elem.async = true
            elem.defer = true
            document.body.insertBefore(elem, document.body.firstChild)
        }
        installGoogleAds();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    },[])

    return (
        <Container>
            <ins
            className="adsbygoogle"
            style={{ width:'275px', height:'600px'}}
            data-ad-client="ca-pub-6350015590431986"
            data-ad-slot="4506318732"
            data-ad-format="auto"
            data-full-width-responsive="true"
        />
        </Container>
    )
}

export default SideAds