import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from '../../reducers/reducer'
import thunkMiddleware from 'redux-thunk'
import { copyGrid } from '../../utils/copyGrid'
import {statePool} from  './statePool'

const randomIndex = Math.floor(Math.random() * 20)
const initialState = {
    challengeGrid: statePool[randomIndex][0],
    workingGrid: copyGrid(statePool[randomIndex][0]),
    solvedGrid: statePool[randomIndex][1],
    hintsArray: statePool[randomIndex][2],
    isThirfty: -1,
    loading: false,
    isCompleted: false,
    isCorrect: false,
}

function configureStore() {
    const composeEnhancers = composeWithDevTools(
        applyMiddleware(thunkMiddleware)
    )
    const store = createStore(reducer, initialState, composeEnhancers)

    return store
}

export default configureStore
