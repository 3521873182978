import styled, { css } from 'styled-components'


export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex: 0.08;
        flex-direction: column;
        max-height: fit-content;
        height: 20%;
        margin: 3%;
    `}
`

